@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient-bg-hero {
  background-color: #131835;
  background-image: radial-gradient(
      at 0% 0%,
      hsl(231deg 47% 14%) 0%,
      transparent 50%
    ),
    radial-gradient(at 10% 10%, #fdbd01, transparent 20%),
    radial-gradient(at 0% 50%, white, transparent 50%)
      radial-gradient(at 10% 10%, #fdbd01, transparent 30%);
}

.gradient-bg-header {
  background-color: #131835;
  background-image: radial-gradient(at 50% 55%, #fdbd01, transparent 50%);
}

.gradient-bg-artworks {
  background-color: #131835;
  background-image: radial-gradient(at 50% 55%, #fdbd01, transparent 50%);
}

.gradient-bg-footer {
  background-color: #131835;
}

.text-gradient {
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.label-gradient {
  background: rgb(19, 24, 53);
  background: linear-gradient(
    31deg,
    rgba(19, 24, 53, 1) 0%,
    rgba(237, 33, 124, 0) 100%
  );
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.lds-dual-ring {
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid-item {
  background-color: hsl(231, 47%, 14%);
  padding: 20px;
  border-radius: 8px;
  height: 10vh;
  width: 10vw;
}

.full-height {
  height: 100%;
}

.equal-box {
  border: 1px;
  padding: 20px;
  flex: 1;
}

.equal-column {
  border: 1px;
  flex: 1;
}

.hpc-column {
  flex: 1;
  padding-top: 130px;
  border: 1px solid #fff;
}

.compress-column {
  border: 1px;
  flex: 1;
  padding: 20px;
}

.hpc-box {
  padding: 0 20px;
}

.hpc-columns {
  text-align: center;
}

@media (max-width: 767px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-item {
    width: 100%;
  }

  .flex-col {
    flex-direction: column;
  }

  .hpc-column {
    padding-top: 50px;
  }

  .compress-column {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1s infinite;
}

.content-container {
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-column {
  flex-basis: calc(33.33% - 10px);
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
  }

  .content-column {
    flex-basis: 100%;
  }
}
@media (max-width: 768px) {
  .menu-list {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0; /* Align to the right side */
    background-color: #131835;
    z-index: 1;
    min-width: 160px;
  }

  .menu-list.active {
    display: flex;
  }

  .menu-list ul {
    width: 100%; /* Make the width full to fill the container */
  }
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
  padding: 5px;
}

.line {
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: transform 0.3s;
}

.hamburger.active .line:nth-child(1) {
  transform: rotate(-45deg) translate(-3px, 0px);
}

.hamburger.active .line:nth-child(2) {
  transform: rotate(45deg) translate(-3px, 0px);
}

.hamburger.active .line:nth-child(3) {
  transform: scale(0);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
